import Vue from "vue";
import orderComplaintConfig from "@/config/orderComplaintConfig.js";
// 客訴單問題類型
Vue.filter("complaintType", (val) => {
    const types = orderComplaintConfig.complaintType;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 客訴單狀態
Vue.filter("complaintStatus", (val) => {
    const types = orderComplaintConfig.complaintStatus;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 客訴單緊急程度
Vue.filter("complaintSeverity", (val) => {
    const types = orderComplaintConfig.complaintSeverity;
    if (types[val]) {
        return types[val];
    }
    return val;
});
