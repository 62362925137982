<template>
    <!-- 彈窗 -->
    <Dialog
        :showDialog="showDialog"
        :customClass="'w-[50%] relative'"
        :loading="loading"
        :clickOutsideClose="false"
        @onCloseDialog="showDialog = false">
        <div class="pop-width md:p-10 px-5 py-3 bg-white rounded">
            <div class="close" @click="showDialog = false">
                <i class="fas fa-times"></i>
            </div>
            <div class="title">新增客訴</div>

            <div class="inputs">
                <div class="input">
                    <div class="label">問題分類 *</div>
                    <el-select v-model="inputs.type" size="small" class="w-full" placeholder="選擇問題分類" clearable>
                        <el-option v-for="(type, index) in problemOptions" :key="index" :label="type.label" :value="type.value">
                            {{ type.label }}
                        </el-option>
                    </el-select>
                </div>
                <div class="input">
                    <div class="label">發起人 手機 *</div>
                    <el-input v-model="inputs.phone" placeholder="請輸入手機" size="small" @change="changePhone"> </el-input>
                    <div v-if="showPhoneError" class="error">手機格式錯誤</div>
                </div>
                <div class="input">
                    <div class="label">緊急程度 *</div>
                    <el-select v-model="inputs.severity" size="small" class="w-full" placeholder="選擇緊急程度" clearable>
                        <el-option v-for="(severity, index) in severityOptions" :key="index" :label="severity.label" :value="severity.value">
                            {{ severity.label }}
                        </el-option>
                    </el-select>
                </div>
                <div class="input">
                    <div class="label">客訴對象 手機 {{ inputs.type == 3 || inputs.type == 7 ? "*" : "" }}</div>
                    <el-input v-model="inputs.defendant_phone" placeholder="請輸入手機" type="phone" size="small" @change="changePhone2"> </el-input>
                    <div v-if="showPhoneError2" class="error">手機格式錯誤</div>
                </div>
                <div v-if="inputs.type == 3 || inputs.type == 7" class="input">
                    <div class="label">客訴訂單編號*</div>
                    <el-input v-model="inputs.order_id" placeholder="請輸入客訴訂單編號" size="small"> </el-input>
                </div>
                <div class="textarea">
                    <div class="label">問題描述 *</div>
                    <el-input v-model="inputs.feedback" placeholder="請輸入問題內容" type="textarea"> </el-input>
                </div>
            </div>
            <!-- 上傳圖片區塊 -->
            <el-upload
                v-if="images.length < 5"
                action="#"
                :limit="10"
                multiple
                :auto-upload="false"
                :on-change="changeFile"
                accept=".jpg, .jpeg, .png"
                list-type="none"
                :file-list="fileList">
                <button class="orange-btn-outline-800 text-xs w-[100px] h-[26px] flex-1 mb-[2px]">上傳截圖</button>
                <div slot="file" class="flex"></div>
            </el-upload>
            <div class="images">
                <div v-for="(i, idx) in images" :key="idx" class="image" :style="{ background: `url('${i.base64}') no-repeat center` }">
                    <div class="trash" @click="deleteIMG(idx)">
                        <i class="far fa-trash-alt"></i>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-3 border-gray-100 pt-2">
                <el-button class="black-btn-outline md:text-sm text-xs h-[40px] w-[150px]" @click="showDialog = false"> 取消 </el-button>
                <el-button class="orange-btn-800 md:text-sm text-xs h-[40px] w-[150px]" :disabled="disabled" @click="confirm"> 新增 </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import moment from "moment";
import Dialog from "@/components/Dialog.vue";
import orderComplaintConfig from "@/config/orderComplaintConfig";
// import { handleErrorMessage } from "@/service/errorMessage";

// base64 轉 Blob
import { dataURLtoBlob } from "@/service/imageTransfer.js";
export default {
    name: "PopupAddComplaint",
    components: { Dialog },
    props: {
        value: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        showDialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        // 問題 options
        problemOptions() {
            const status = orderComplaintConfig.complaintType;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 警急程度 options
        severityOptions() {
            const status = orderComplaintConfig.complaintSeverity;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
    },
    data() {
        return {
            // 輸入
            loading: false,
            inputs: {
                phone: "", // 發起人手機
                defendant_phone: "", // 客訴對象 手機
                type: "",
                severity: "",
                feedback: "",
                order_id: "",
            },
            images: [],
            fileList: [],
            disabled: true,
            showPhoneError: false,
            showPhoneError2: false,
        };
    },
    methods: {
        // 更新桌機圖片
        changeFile(file, fileList) {
            if (file.size / (1024 * 1024) > 5) {
                this.fileList = [];
                alert("檔案大小超出5mb");
                return;
            }
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                const fileStamp = {
                    time: moment().format("HHMMSSSS"),
                    url: file.url,
                    file: file.raw,
                    base64: reader.result,
                };
                if (this.images.length < 5) {
                    this.fileList = fileList.slice(-3);
                    this.images.push(fileStamp);
                } else {
                    alert("檔案張數超出限制");
                    return;
                }
            };
        },
        deleteIMG(idx) {
            this.images.splice(idx, 1);
        },
        confirm() {
            this.loading = true;
            const {
                phone, // 發起人手機
                defendant_phone, // 客訴對象 手機
                type,
                severity,
                feedback,
                order_id,
            } = this.inputs;

            const result = new FormData();
            result.append("phone", phone);
            if (type == 3 || type == 7) {
                result.append("order_id", order_id);
            }

            if (defendant_phone) {
                result.append("defendant_phone", defendant_phone);
            }

            result.append("type", type);
            result.append("severity", severity);
            result.append("feedback", feedback);

            this.images.forEach((i) => {
                if (i.base64) {
                    const blob = dataURLtoBlob(i.base64);
                    result.append("images[]", blob);
                }
            });

            this.$api
                .CreateUserFeedback(result)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "新增成功",
                    });
                    this.$emit("success");
                })
                .catch((err) => {
                    const errMessage = err.response?.data.error.message ? err.response?.data.error.message : "新增失敗";
                    this.$message({
                        type: "error",
                        message: errMessage,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        changePhone(val) {
            const phoneMatch = /886\d{7,17}$/.test(val);
            this.showPhoneError = false;
            if (phoneMatch === false) {
                this.showPhoneError = true;
            }
        },
        changePhone2(val) {
            const phoneMatch = /886\d{7,17}$/.test(val);
            this.showPhoneError2 = false;
            if (phoneMatch === false) {
                this.showPhoneError2 = true;
            }
        },
    },
    watch: {
        inputs: {
            deep: true,
            handler(val) {
                const {
                    phone, // 發起人手機
                    type,
                    severity,
                    feedback,
                    order_id,
                    defendant_phone,
                } = val;

                const phoneMatch = /886\d{7,17}$/.test(phone);
                const defendantPhoneMatch = /886\d{7,17}$/.test(defendant_phone);

                if (type == 3 || type == 7) {
                    if (phoneMatch && type && severity && feedback && order_id && defendantPhoneMatch) {
                        this.showPhoneError = false;
                        this.showPhoneError2 = false;
                        this.disabled = false;
                    } else {
                        this.disabled = true;
                    }
                } else {
                    if (phoneMatch && type && severity && feedback) {
                        this.showPhoneError = false;
                        this.disabled = false;
                    } else {
                        this.disabled = true;
                    }
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.pop-width {
    width: 100%;
    min-width: 100%;
    margin: 0;
    .title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}

.inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    .input {
        width: 48%;
        margin: 8px 0;
    }
    .textarea {
        width: 100%;
        margin: 8px 0;
    }
    .label {
        font-size: 14px;
        color: #757575;
        margin-bottom: 5px;
    }
}

.images {
    width: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    .image {
        display: flex;
        position: relative;
        width: 45%;
        min-width: 45%;
        height: 200px;
        margin: 10px 5px;
        background-size: contain !important;
        &:hover {
            cursor: pointer;
            .trash {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background: rgba(0, 0, 0, 0.2);
                font-size: 20px;
            }
        }
        .trash {
            display: none;
        }
    }
}
.error {
    font-size: 12px;
    color: red;
    margin-top: 2px;
}
</style>
