import Vue from "vue";

import orderConfig from "@/config/orderConfig";

// 訂單狀態
Vue.filter("dateStatus", (val) => {
    const types = orderConfig.orderStatus;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 續約單狀態
Vue.filter("extensionStatus", (val) => {
    const types = orderConfig.extensionStatus;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 交易類型
Vue.filter("transactionType", (val) => {
    const types = orderConfig.transactionType;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 快閃折抵金事件類型
Vue.filter("voucherType", (val) => {
    const types = orderConfig.voucherTypes;
    if (types[val]) {
        return types[val];
    }
    return val;
});
